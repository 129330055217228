import "./App.css";
import { Switch, Route, Redirect } from "react-router-dom";
import React from 'react';

import "../node_modules/nouislider/dist/nouislider.min";

import {useEffect} from "react";
import {setWebpSupport} from "./store/actions";
import {useDispatch} from "react-redux";
import AsyncComponent from './AsynComponent'

const AddMelk = AsyncComponent(() => import('../src/components/AddMelk/AddMelk').then(module => module.default))
const TimeModal = AsyncComponent(() => import('./components/TimeModal/TimeModal').then(module => module.default))
const ContactUs = AsyncComponent(() => import('./components/ContactUs/ContactUs').then(module => module.default))
const Report = AsyncComponent(() => import('./components/Report/Report').then(module => module.default))
const Faq = AsyncComponent(() => import('./components/Faq/Faq').then(module => module.default))
const AboutUs = AsyncComponent(() => import('./components/AboutUs/AboutUs').then(module => module.default))
const Legal = AsyncComponent(() => import('./components/Legal/Legal').then(module => module.default))
const Career = AsyncComponent(() => import('./components/Career/CareerSearch').then(module => module.default))
const CareerItem = AsyncComponent(() => import('./components/Career/CareerItem').then(module => module.default))
const CareerCv = AsyncComponent(() => import('./components/Career/CareerCv').then(module => module.default))
const FilterNew = AsyncComponent(() => import('./components/New Filter/FilterNew').then(module => module.default))
const NewProfile = AsyncComponent(() => import('./components/NewProfile/NewProfile').then(module => module.default))
const ConstructorList = AsyncComponent(() => import('./components/Lists/ConstructorList/ConstructorList').then(module => module.default))
const AnExpert = AsyncComponent(() => import('./components/Lists/ExpertList/AnExpert/AnExpert').then(module => module.default))
const Requests = AsyncComponent(() => import('./components/Requests/Requests').then(module => module.default))
const MelkRequest = AsyncComponent(() => import('./components/Requests/MelkRequest').then(module => module.default))
const AddBuilder = AsyncComponent(() => import('./components/AddBuilder/AddBuilder').then(module => module.default))
const ABuilder = AsyncComponent(() => import('./components/Lists/ConstructorList/ABuilder/ABuilder').then(module => module.default))
const ExpertList = AsyncComponent(() => import('./components/Lists/ExpertList/ExpertList').then(module => module.default))
const UserRequests = AsyncComponent(() => import('./components/Requests/UserRequests').then(module => module.default))
const NewProfileBuilder = AsyncComponent(() => import('./components/NewProfileBuilder/NewProfileBuilder').then(module => module.default))
const HomePage = AsyncComponent(() => import('./containers/HomePage copy/HomePage').then(module => module.default))
const Conversion = AsyncComponent(() => import('./containers/Conversion/Conversion').then(module => module.default))
const Transformation = AsyncComponent(() => import('./containers/Transformation/Transformation').then(module => module.default))
const ShowMelk = AsyncComponent(() => import('./components/ShowMelk/ShowMelk').then(module => module.default))
const LogMobile = AsyncComponent(() => import('./components/LogReg/Login/LogMobile/LogMobile').then(module => module.default))
const Moshaver = AsyncComponent(() => import('./components/Moshaver/Moshaver').then(module => module.default))
const Register = AsyncComponent(() => import('./components/LogReg/Register/Register').then(module => module.default))
const AfterCon = AsyncComponent(() => import('./components/Moshaver/AfterCon/AfterCon').then(module => module.default))
const Bookmark = AsyncComponent(() => import('./components/Bookmark/Bookmark').then(module => module.default))
const RegModal = AsyncComponent(() => import('./components/Moshaver/RegModal/RegModal').then(module => module.default))
const Profile = AsyncComponent(() => import('./components/Profile/Profile').then(module => module.default))




function App() {

  const dispatch = useDispatch();
  useEffect(()=>{

    dispatch(setWebpSupport())
  }, [])

  return (
    <div>
      <Switch>
        <Route path="/" exact component={HomePage} />
        <Route path="/fields" component={Moshaver} />
        <Route path="/Transformation" component={Transformation} />

        {/*<Route path="/filter" component={FilterPage} />*/}
        <Route path="/Conversion" component={Conversion} />

        <Route path="/files/:id" component={ShowMelk} />
        <Route path="/register" component={LogMobile} />
        <Route path="/registername" component={Register} />
        <Route path="/chooseadviser" component={AfterCon} />
        <Route path="/bookmark" component={Bookmark} />
        <Route path="/registeradviser" component={RegModal} />
        <Route path="/profile" component={Profile} />
        <Route path="/add-melk" component={AddMelk} />
        <Route path="/edit-melk/:id" component={AddMelk} />
        <Route path="/modal" component={TimeModal} />
        <Route path="/contact-us" component={ContactUs} />
        <Route path="/report" component={Report} />
        <Route path="/faq" component={Faq} />
        <Route path="/about-us" component={AboutUs} />
        <Route path="/legal" component={Legal} />
        <Route path="/career" component={Career} />
        <Route path="/career-item" component={CareerItem} />
        <Route path="/career-cv" component={CareerCv} />
        <Route path="/filter-new" component={FilterNew} />
        <Route path="/expertlist" component={ExpertList} />
        <Route path="/builderslist" component={ConstructorList} />
        <Route path="/newProfile" component={NewProfile} />
        <Route path="/filter" component={FilterNew} />
        <Route path="/expertpage/:id" component={AnExpert} />
        <Route path="/builderpage/:id" component={ABuilder} />
        <Route path="/editbuilderpage/:id" component={NewProfileBuilder} />

        <Route path='/bookmark' component={Bookmark}/>
        <Route path='/registeradviser' component={RegModal}/>
        <Route path='/profile' component={Profile}/>
        <Route path='/add-melk' component={AddMelk}/>
        <Route path='/specific-melk' component={AddMelk}/>
        <Route path='/edit-melk/:id' component={AddMelk}/>
        <Route path='/modal' component={TimeModal}/>
        <Route path='/contact-us' component={ContactUs}/>
        <Route path='/report' component={Report}/>
        <Route path='/faq' component={Faq}/>
        <Route path='/about-us' component={AboutUs}/>
        <Route path='/legal' component={Legal}/>
        <Route path='/career' component={Career}/>
        <Route path='/career-item' component={CareerItem}/>
        <Route path='/career-cv' component={CareerCv}/>
        <Route path='/filter-new' component={FilterNew}/>
        <Route path='/filter' component={FilterNew}/>
        <Route path='/requests' component={Requests}/>
        <Route path='/melk-request' component={MelkRequest}/>
        <Route path='/expertlist' component={ExpertList}/>
        <Route path='/newProfile' component={NewProfile}/>
        <Route path='/addbuilder' component={AddBuilder}/>
        <Route path='/user-request' component={UserRequests}/>
        <Redirect to='/'/>

      </Switch>
      {/* <HomePage/> */}
      {/* <FilterPage/> */}
      {/* <Conversion/> */}

    </div>
  );
}

export default App;
