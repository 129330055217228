import * as actionTypes from "./actionTypes";
import accounts from "../../api/accounts";

export const setUserData = (
  userID,
  userToken,
  slug,
  name,
  phone,
  avatar,
  email,
  title,
  whatsappaccount,
  rentmelk,
  buymelk,
  successfulagreement
) => ({
  type: actionTypes.SET_USER_DATA,
  userID: userID,
  userToken: userToken,
  slug: slug,
  name: name,
  phone: phone,
  avatar: avatar,
  email: email,
  title: title,
  whatsappaccount: whatsappaccount,
  rentmelk:rentmelk,
  buymelk:buymelk,
  successfulagreement:successfulagreement,
});

export const setExpertField = (field) => ({
  type: actionTypes.SET_EXPERT_FIELD,
  field: field,
});
export const setExpertStatus = (status) => ({
  type: actionTypes.SET_EXPERT_STATUS,
  status: status,
});

export const tokenValidationError = () => ({
  type: actionTypes.TOKEN_VALIDATION_ERROR,
});

export const getUserAuthData = () => (dispatch) => {
  const userToken = localStorage.getItem("nikToken");
  accounts
    .get("", {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    })
    .then((response) => {
      if (response.data.ok) {
        dispatch(
          setUserData(
            response.data.user.id,
            userToken,
            response.data.user.user_group.slug,
            response.data.user.name,
            response.data.user.phone,
            response.data.user.avatar,
            response.data.user.email,
            response.data.user.title,
            response.data.user.whatsapp_account,
            response.data.user.rent_melk,
            response.data.user.buy_melk,
            response.data.user.successful_agreement

        
          )
        );
      } else {
        dispatch(tokenValidationError());
      }
    });
};

export const changeExpertStatus = (status) => (dispatch) => {
  const userToken = localStorage.getItem("nikToken");
  const update = {
    update: {
      status: status,
    },
  };
  accounts
    .post("", update, {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    })
    .then((response) => {
      if (response.data.ok) {
        dispatch(setExpertStatus(status));
      }
    });
};
