import * as actionTypes from "../actions/actionTypes";

const initalState = {
  getpost: [],
  getbuilder: [],
  getexpert: [],
  isOkInRegister:false
};

const getThePostOfBlog = (state, action) => ({
  ...state,
  getpost: action.getpost,
});
const getTheBuilders = (state, action) => ({
  ...state,
  getbuilder: action.getbuilder,
});
const getExpert = (state, action) => ({
  ...state,
getexpert: action.getexpert,
});
 const isOkInRegister = (state, action) => ({
  ...state,
  isOkInRegister: action.isOkInRegister,
 
});
const reducer = (state = initalState, action) => {
  switch (action.type) {
    case actionTypes.IS_USER_OK_IN_PRO:
      return isOkInRegister(state, action);
    case actionTypes.GET_POSTS_IN_BLOG:
      return getThePostOfBlog(state, action);
    case actionTypes.GET_BUILDERS:
      return getTheBuilders(state, action);
    case actionTypes.GET_EXPERT:
      return getExpert(state, action);
    default:
      return state;
  }
};

export default reducer;
