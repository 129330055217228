import React , {PureComponent} from "react";

export default function AsyncComponent(getComponent){
    return class extends PureComponent {
        state= {
            component: null
        }

        componentDidMount() {
            getComponent().then(component => this.setState({component}))
        }

        render() {
            let Component = this.state.component
            return Component ? <Component history={this.props.history} location={this.props}/> : null
        }
    }
}