import * as actionTypes from "./actionTypes";
import axios from "axios";

export const setChange = (e) => ({
    type: actionTypes.SET_CHANGE,
    data: e.target.value.replace(',', "")
        .replace(',', "")
        .replace(',', "")
        .replace(',', ""),
    name: e.target.name,
});

export const setChange2 = (value, name) => ({
    type: actionTypes.SET_CHANGE_PSM,
    data: name === 'specific' ? value : value.toString().replace(',', "")
        .replace(',', "")
        .replace(',', "")
        .replace(',', ""),
    name: name,
});


export const setSelect = (e) => ({
    type: actionTypes.SET_SELECT,
    data: e.value,
    name: e.name,
    district: e.district
});

export const setChange3 = (e) => ({
    type: actionTypes.SET_CHANGE_3,
    data: e.value,
    name: e.name,
});

export const setDate = (e) => ({
    type: actionTypes.SET_DATE,
    data: e.year + '/' + e.month + '/' + e.day,
    name: 'quite_date',
});

export const setLatLng = (data) => ({
    type: actionTypes.SET_LAT_LNG,
    data,
});

export const okSend = (data) => ({
    type: actionTypes.OK_SEND,
    data,
});

export const okUpdate = (data) => ({
    type: actionTypes.OK_UPDATE,
    data,
});

export const okAddNeighborhood = (data) => ({
    type: actionTypes.OK_ADD_NEIGHBORHOOD,
    data,
});

export const okUpload = (data) => ({
    type: actionTypes.OK_UPLOAD,
    data,
});


export const setCheck = (value, name) => ({
    type: actionTypes.SET_CHECK,
    value,
    name: name,
});

export const setNeighborhood = (value) => ({
    type: actionTypes.SET_NEIGHBORHOOD,
    value,
});

export const setEdit = (data) => ({
    type: actionTypes.SET_EDIT,
    data,
});

export const setEditOk = (data) => ({
    type: actionTypes.SET_EDIT_OK,
    data,
});

export const setNeighborhoodsList = (data) => ({
    type: actionTypes.SET_NEIGHBORHOODS_LIST,
    data,
});

export const setSort = (data) => ({
    type: actionTypes.SET_SORT,
    data,
});


export const setProgress = (data) => ({
    type: actionTypes.SET_PROGRESS,
    data,
});

export const setFile = (name,value) => ({
    type: actionTypes.SET_FILE,
    name,
    value,
});

export const deleteFile = (index) => ({
    type: actionTypes.DELETE_FILE,
    index,
});

export const uploadFile = (file) => {
    return (dispatch) => {
        let data = new FormData();
        data.append("file", file)
        axios
            .post('https://nikcdn.nikboom.com/upload', data, {
                onUploadProgress: (progressEvent) => {
                    dispatch(setProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total)))
                },
                headers: {
                    'Content_type': 'multipart/form-data',
                    Authorization: `Bearer ${localStorage.getItem("nikToken")}`,
                    'Cache-Control': 'max-age=31536000'
                },
            }).catch((err) => {
            console.log(err)
        }).then((response) => {
            console.log(response)
            if (file.type !== 'image/webp'){
                dispatch(setFile('alternative_image_links',response.data.url));
            } else{
                dispatch(setFile('image_links',response.data.url));
            }
            dispatch(okUpload(false))
            dispatch(setProgress(0))
        })
            .catch((error) => {
                console.log(error);
            });
    };
}

export const doneAddMelk = (data) => {
    return (dispatch) => {
        axios
            .post(`${process.env.REACT_APP_DOMAINS_API}/${process.env.REACT_APP_DOMAINS_API_VERSION}/houses`, {houses: [data]}, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("nikToken")}`,
                    'Cache-Control': 'max-age=31536000'
                },
            }).catch((err) => {
            console.log(err)
        }).then((response) => {
            dispatch(okSend(response.data.ok));
        }).catch((error) => {
            console.log(error);
        });
    }
}

export const getNeighborhoods = () => {
    return (dispatch) => {
        axios
            .get(`${process.env.REACT_APP_DOMAINS_API}/${process.env.REACT_APP_DOMAINS_API_VERSION}/houses/neighborhoods/load`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("nikToken")}`,
                        'Cache-Control': 'max-age=31536000'
                    },
                }).catch((err) => {
            console.log(err)
        }).then((response) => {
            console.log(response)
            dispatch(setNeighborhoodsList(response.data.result));
        }).catch((error) => {
            console.log(error);
        });
    };
}


export const updateMelk = (id, data) => {
    return (dispatch) => {
        axios
            .post(`${process.env.REACT_APP_DOMAINS_API}/${process.env.REACT_APP_DOMAINS_API_VERSION}/houses/update`,
                {
                    houseId: id,
                    update: data
                }, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("nikToken")}`,
                        'Cache-Control': 'max-age=31536000'
                    },
                }).catch((err) => {
            console.log(err)
        }).then((response) => {
            dispatch(okUpdate(response.data.ok));
        }).catch((error) => {
            console.log(error);
        });
    }
}

export const addNeighborhood = (value , district) => {
    return (dispatch) => {
        axios
            .post(`${process.env.REACT_APP_DOMAINS_API}/${process.env.REACT_APP_DOMAINS_API_VERSION}/houses/neighborhoods`,
                {
                    neighborhoods: [
                        {
                            name: value,
                            district
                        }
                    ]
                }, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("nikToken")}`,
                        'Cache-Control': 'max-age=31536000'
                    },
                }).catch((err) => {
            console.log(err)
        }).then((response) => {
            dispatch(okAddNeighborhood(response.data.ok));
        }).catch((error) => {
            console.log(error);
        });
    }
}