import * as actionTypes from "../actions/actionTypes";

const initalState = {
    okvisit: false,
};

const setDoneVisit = (state, action) => ({
    ...state,
    okvisit: action.state,
});

const visitReducer = (state = initalState, action) => {
    switch (action.type) {
        case actionTypes.SET_DONE_VISIT:
            return setDoneVisit(state, action);
        default:
            return state;
    }
};

export default visitReducer;
