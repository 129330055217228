import * as actionTypes from "../actions/actionTypes";

const initalState = {
  usage: null,
  propertytype:null,
  dealtype: null,
  address: null,
  allhouses: [],
  isokinfilter: false,
  minsize: 0,
  maxsize: 1000,
  minprice: 0,
  maxprice:999999999999,
  allhouseslen: 0,
  minroomquantity: 0,
  balcony: null,
  //age
  minage: 0,
  maxage: 100,
  warehouse: null,
  yard: null,
  heatsystem: null,
  pool: null,
  elevator: null,
  sauna: "",
  jacuzzi: "",
  toilet: "",
  minloan:0,
  maxloan:999999999999,
  minrent:0,
  maxrent:999999999999,
  page: 0,
  length: 0,
  currentPage:0,
};
 const setExpertHousesPage = (state, action) => ({
  ...state,
  page: action.page,
});
 const setExpertHousesLength = (state, action) => ({
  ...state,
  length: action.length,
});
const setUsage = (state, action) => ({
  ...state,
  usage: action.usage,
});
 const setPropertyType = (state, action) => ({
  ...state,
  propertytype: action.propertytype,
});
const setDealType = (state, action) => ({
  ...state,
  dealtype: action.dealtype,
});
const settoilet = (state, action) => ({
  ...state,
  toilet: action.toilet,
});

const setsauna = (state, action) => ({
  ...state,
  sauna: action.sauna,
});

const setjacuzzi = (state, action) => ({
  ...state,
  jacuzzi: action.jacuzzi,
});

const setHeatsystem = (state, action) => ({
  ...state,
  heatsystem: action.heatsystem,
});
const minSize = (state, action) => ({
  ...state,
  minsize: action.minsize,
});
const MaxSize = (state, action) => ({
  ...state,
  maxsize: action.maxsize,
});

const minLoan = (state, action) => ({
  ...state,
  minloan: action.minloan,
});
const maxLoan = (state, action) => ({
  ...state,
  maxloan: action.maxloan,
});

const minRent = (state, action) => ({
  ...state,
  minrent: action.minrent,
});
const maxRent = (state, action) => ({
  ...state,
  maxrent: action.maxrent,
});

const MinPrice = (state, action) => ({
  ...state,
  minprice: action.minprice,
});
const MaxPrice = (state, action) => ({
  ...state,
  maxprice: action.maxprice,
});
//rom_qintity
const roomQuantity = (state, action) => ({
  ...state,
  minroomquantity: action.minroomquantity,
});
//balcony
const setBAlcony = (state, action) => ({
  ...state,
  balcony: action.balcony,
});
//age
const setMinAge = (state, action) => ({
  ...state,
  minage: action.minage,
});

const setMaxAge = (state, action) => ({
  ...state,
  maxage: action.maxage,
});
//yard
const setyard = (state, action) => ({
  ...state,
  yard: action.yard,
});
//WareHouse
const WareHouse = (state, action) => ({
  ...state,
  type: actionTypes.SET_WAREHOUSE,
  warehouse: action.warehouse,
});
//pool
const setpool = (state, action) => ({
  ...state,
  pool: action.pool,
});
//elavator
const setelevator = (state, action) => ({
  ...state,
  elevator: action.elevator,
});
const setAdress = (state, action) => ({
  ...state,
  address: action.address,
});
const allHouses = (state, action) => ({
  ...state,
  allhouses: action.allhouses,
});
const allHousesLength = (state, action) => ({
  ...state,
  allhouseslen: action.allhouseslen,
});
const isOkFilter = (state, action) => ({
  ...state,
  isokinfilter: action.isokinfilter,
});
 const setCurrentPage = (state, action)  => ({
  ...state,
  currentPage: action.currentPage,
 
});
const reducer = (state = initalState, action) => {
  switch (action.type) {
    case actionTypes.SET_CURRENT_PAGE:
      return setCurrentPage(state, action);


    case actionTypes.EXPERT_HOUSES_SET_PAGE:
      return setExpertHousesPage(state, action);

      case actionTypes.SET_EXPERT_HOUSES_LENGTH:
        return setExpertHousesLength(state, action);

    case actionTypes.SET_USAGE:
      return setUsage(state, action);
      case actionTypes.SET_PROPERTY_TYPE:
        return setPropertyType(state, action);
    case actionTypes.SET_DEAL_TYPE:
      return setDealType(state, action);
    case actionTypes.SET_USER_ADDRESS:
      return setAdress(state, action);
    case actionTypes.GET_ALL_HOUSES:
      return allHouses(state, action);
    case actionTypes.IS_OK_IN_FILTER:
      return isOkFilter(state, action);
    //SIZE OF HOUSE
    case actionTypes.SET_MIN_SIZE:
      return minSize(state, action);
    case actionTypes.SET_MAX_SIZE:
      return MaxSize(state, action);
    case actionTypes.SET_MIN_LOAN:
      return minLoan(state, action);
    case actionTypes.SET_MAX_LOAN:
      return maxLoan(state, action);
    case actionTypes.SET_MIN_RENT:
      return minRent(state, action);
    case actionTypes.SET_MAX_RENT:
      return maxRent(state, action);
    //Room quntity
    case actionTypes.MIN_ROOM_QUANTITY:
      return roomQuantity(state, action);
    //balcony
    case actionTypes.SET_BALCONY:
      return setBAlcony(state, action);
    //age
    case actionTypes.SET_MIN_AGE:
      return setMinAge(state, action);
    case actionTypes.SET_MAX_AGE:
      return setMaxAge(state, action);
    //yard
    case actionTypes.SET_MIN_PRICE:
      return MinPrice(state, action);
    case actionTypes.SET_MAX_PRICE:
      return MaxPrice(state, action);
    case actionTypes.SET_YARD:
      return setyard(state, action);
    //warehouse
    case actionTypes.SET_WAREHOUSE:
      return WareHouse(state, action);

    case actionTypes.SET_POOL:
      return setpool(state, action);

    case actionTypes.SET_ELEVATOR:
      return setelevator(state, action);

    case actionTypes.SET_TOILET:
      return settoilet(state, action);
    case actionTypes.SET_SAUNA:
      return setsauna(state, action);
    case actionTypes.SET_JACUZZI:
      return setjacuzzi(state, action);

    case actionTypes.SET_HEAT:
      return setHeatsystem(state, action);

    case actionTypes.HOUSE_LENGTH:
      return allHousesLength(state, action);
    default:
      return state;
  }
};

export default reducer;
