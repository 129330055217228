import * as actionTypes from "../actions/actionTypes";

const initialState = {
  bookhomes: [],
  oksavebook: false,
  okdeletebook: false,
};

const getHomeinBook = (state, action) => ({
  ...state,
  bookhomes: action.bookhomes,
});
const OKinSavedBokk = (state, action) => ({
  ...state,
  oksavebook: action.oksavebook,
});
const OKinDeleteBook = (state, action) => ({
  ...state,
  okdeletebook: action.okdeletebook,
});
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_BOOK_HOMES:
      return getHomeinBook(state, action);
    case actionTypes.OK_BOOK_HOMES:
      return OKinSavedBokk(state, action);
    case actionTypes.OK_DELETE_BOOK_HOMES:
      return OKinDeleteBook(state, action);

    default:
      return state;
  }
};

export default reducer;
