import * as actionTypes from "../actions/actionTypes";
import produce from "immer";

const initialState = {
  page: 0,
  neighborhoods: null,
  length: 0,
  loading: false,
};

export default produce((draft, action) => {
  switch (action.type) {
    case actionTypes.LOAD_EXPERT_HOUSES:
      draft.neighborhoods = action.neighborhoods;
      return;
    case actionTypes.EXPERT_HOUSES_SET_PAGE:
      draft.page = action.page;
      return;
    case actionTypes.FLUSH_HOUSES_DATA:
      draft.neighborhoods = null;
      draft.page = 0;
      draft.length = 0;
      return;
    case actionTypes.SET_EXPERT_HOUSES_LENGTH:
      draft.length = action.length;
      return;
    case actionTypes.SET_EXPERT_HOUSES_LOADING:
      draft.loading = action.isLoading;
      return;
    default:
      return;
  }
}, initialState);
