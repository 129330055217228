import * as actionTypes from "../actions/actionTypes";

const initialState = {
    query: {
        price: {min: 0, max: 999999999999},
        size: {min: 0, max: 9999},
        loan: {min: 0, max: 999999999999},
        rental_fee: {min: 0, max: 999999999999},
    },
    result: [],
    neighbors: [],
    page: '0',
    pages: 0,
    hasMoreItems: true,
    spinner: true,
}

const setSearchData = (state, action) => ({
    ...state,
    query: {...state.query, [action.name]: action.value},
});

const setSpinner = (state, action) => ({
    ...state,
    spinner: action.state,
});


const setRemoveFilters = (state) => ({
    ...state,
    query: {
        price: {min: 0, max: 999999999999},
        size: {min: 0, max: 9999},
        loan: {min: 0, max: 999999999999},
        rental_fee: {min: 0, max: 999999999999},
    },
});

const setNeighbors = (state, action) => ({
    ...state,
    neighbors: action.neighbors,
});

const setResultData = (state, action) => {
    let join = state.page !== '0' ?  state.result.concat(action.data) : action.data
    return {
        ...state,
        result: join,
    }
};

const setCurrentPage = (state, action) => ({
    ...state,
    [action.name]: action.data,
});

const filterNewReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_SEARCH_DATA:
            return setSearchData(state, action);
        case actionTypes.SET_RESULT_DATA:
            return setResultData(state, action);
        case actionTypes.SET_CURRENT_PAGE:
            return setCurrentPage(state, action);
        case actionTypes.SET_NEIGHBORS:
            return setNeighbors(state, action);
        case actionTypes.SET_REMOVE_FILTERS:
            return setRemoveFilters(state, action);
        case actionTypes.SET_SPINNER:
            return setSpinner(state, action);
        default:
            return state;
    }
};

export default filterNewReducer;