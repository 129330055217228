import * as actionTypes from "../actions/actionTypes";

const initalState = {
  userPhoneNumber: null,
  registerd: null,
  isokinmobnum: null,
  typee: null,
  getmessage: null,
  gettype: null,
  //code
  userCode: null,
  isokinconnum: null,
  //field
  field: null,
  control: null,
  district: null,
  surface: null,
  //register
  username: null,
  useremail: null,
  isOkInRegister: null,
  avatarr: null,
  //takhsise moshaver
  avatar: null,
  name: null,
  isthreadok: null,
  phoneExpert:"",
  whatsappExpert:'',
  support: false,
};

const setUserPhoneNumber = (state, action) => ({
  ...state,
  userPhoneNumber: action.phoneNumber,
});

const setWebpSupport = (state, action) => ({
  ...state,
  support: action.support,
});

const setExpertPhone = (state, action) => ({
  ...state,
  phoneExpert: action.phoneExpert,
});

const isregister = (state, action) => ({
  ...state,
  registerd: action.registerd,
});

const isokinmobnum = (state, action) => ({
  ...state,
  isokinmobnum: action.isokinmobnum,
});

const getMessage = (state, action) => ({
  ...state,
  getmessage: action.getmessage,
});
const getType = (state, action) => ({
  ...state,
  gettype: action.gettype,
});

//code
const setUserVerificationCode = (state, action) => ({
  ...state,
  userCode: action.userCode,
});

const isOkInConnum = (state, action) => ({
  ...state,
  isokinconnum: action.isokinconnum,
});

const setTypeinexpert = (state, action) => ({
  ...state,
  typee: action.typee,
});
//field
const setField = (state, action) => ({
  ...state,
  field: action.field,
});
const setcontrol = (state, action) => ({
  ...state,
  control: action.control,
});
const setdistrict = (state, action) => ({
  ...state,
  district: action.district,
});
const setSurface = (state, action) => ({
  ...state,
  surface: action.surface,
});

//register
const setUserName = (state, action) => ({
  ...state,
  username: action.username,
});
const setAvatar = (state, action) => ({
  ...state,
  avatarr: action.avatarr,
});
const setUserEmail = (state, action) => ({
  ...state,
  useremail: action.useremail,
});
const isOkInRegister = (state, action) => ({
  ...state,
  isOkInRegister: action.isOkInRegister,
});
//takhsise moshaver
const setExpertAvatar = (state, action) => ({
  ...state,
  avatar: action.avatar,
});
const setExpertName = (state, action) => ({
  ...state,
  name: action.name,
});
 const setExpertWhatsApp = (state, action) => ({
...state,
	whatsappExpert: action.whatsappExpert,
});
const isThreadOk = (state, action) => ({
  ...state,
  isthreadok: action.isthreadok,
});

const reducer = (state = initalState, action) => {
  switch (action.type) {
    case actionTypes.SET_USER_PHONE_NUMBER:
      return setUserPhoneNumber(state, action);

    case actionTypes.USER_IS_REGISTERD:
      return isregister(state, action);

    case actionTypes.IS_OK_IN_MOBNUM:
      return isokinmobnum(state, action);

    case actionTypes.GET_MESSAGE:
      return getMessage(state, action);
    case actionTypes.GET_TYPE:
      return getType(state, action);
    //code
    case actionTypes.SET_USER_VERFICATION_CODE:
      return setUserVerificationCode(state, action);

    case actionTypes.IS_USER_OK_IN_CONNUM:
      return isOkInConnum(state, action);
    case actionTypes.GET_TYPE_FROM_EXPERT:
      return setTypeinexpert(state, action);
    //field
    case actionTypes.SET_FIELD:
      return setField(state, action);
    case actionTypes.SET_CONTROL:
      return setcontrol(state, action);
    case actionTypes.SET_DISTRICT:
      return setdistrict(state, action);
    case actionTypes.SET_SURFACE:
      return setSurface(state, action);
    //register
    case actionTypes.SET_USER_NAME:
      return setUserName(state, action);
    case actionTypes.SET_USER_EMAIL:
      return setUserEmail(state, action);
    case actionTypes.IS_USER_OK_IN_PRO:
      return isOkInRegister(state, action);
    case actionTypes.SET_AVATAR:
      return setAvatar(state, action);
    //takhsise moshaver
    case actionTypes.IS_THREAD_OK:
      return isThreadOk(state, action);
    case actionTypes.SET_EXPERT_AVATAR:
      return setExpertAvatar(state, action);
    case actionTypes.SET_WEBP_SUPPORT:
      return setWebpSupport(state, action);
    case actionTypes.SET_EXPERT_NAME:
      return setExpertName(state, action);
      case actionTypes.SET_PHONE_OF_EXPERT:
      return setExpertPhone(state, action);
      case actionTypes.SET_WHATSAPP_OF_EXPERT:
      return setExpertWhatsApp(state, action);
    // case actionTypes.USER_LOGOUT:
    //   return {
    //     ...initalState,
    //   };

    default:
      return state;
  }
};

export default reducer;
