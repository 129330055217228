import * as actionTypes from "../actions/actionTypes";

const initalState = {
  username: null,
  useremail: null,
  isOkInRegister: false,
};

const setUserName = (state, action) => ({
  ...state,
  username: action.username,
});

const setUserEmail = (state, action) => ({
  ...state,
  useremail: action.useremail,
});
const isOkInRegister = (state, action) => ({
  ...state,
  isOkInRegister: action.isOkInRegister,
});
const reducer = (state = initalState, action) => {
  switch (action.type) {
    case actionTypes.SET_USER_NAME:
      return setUserName(state, action);
    case actionTypes.SET_USER_EMAIL:
      return setUserEmail(state, action);
    case actionTypes.IS_USER_OK_IN_PRO_USERS:
      return isOkInRegister(state, action);
    default:
      return state;
  }
};

export default reducer;
