// Melks
export const EXPERT_HOUSES_SET_PAGE = "EXPERT_HOUSES_SET_PAGE";
export const EXPERT_HOUSES_SET_PAGE2="EXPERT_HOUSES_SET_PAGE2"
export const SET_EXPERT_HOUSES_LENGTH = "SET_EXPERT_HOUSES_LENGTH";
export const LOAD_EXPERT_HOUSES = "LOAD_EXPERT_HOUSES";
export const FLUSH_HOUSES_DATA = "FLUSH_HOUSES_DATA";
export const SET_EXPERT_HOUSES_LOADING = "SET_EXPERT_HOUSES_LOADING";

export const SET_CURRENT_PAGE = "SET_CURRENT_PAGE"
//searcFilter
export const SET_USER_ADDRESS = "SET_USER_ADDRESS";
export const GET_ALL_HOUSES = "GET_ALL_HOUSES";
export const IS_OK_IN_FILTER = "IS_OK_IN_FILTER";
export const HOUSE_LENGTH="HOUSE_LENGTH"
//searchFilter====size of house
export const SET_MIN_SIZE = "SET_MIN_SIZE";
export const SET_MAX_SIZE = "SET_MAX_SIZE";
export const SET_MIN_LOAN = "SET_MIN_LOAN";
export const SET_MAX_LOAN = "SET_MAX_LOAN";
export const SET_MIN_RENT = "SET_MIN_RENT";
export const SET_MAX_RENT = "SET_MAX_RENT";
//house room-quntity
export const MIN_ROOM_QUANTITY = "MIN_ROOM_QUANTITY";
//age
export const SET_MIN_AGE = "SET_MIN_AGE";
export const SET_MAX_AGE = "SET_MAX_AGE";
export const SET_MIN_PRICE = "SET_MIN_PRICE";
export const SET_MAX_PRICE = "SET_MAX_PRICE";
//balcony
export const SET_BALCONY = "SET_BALCONY";
//yard
export const SET_YARD = "SET_YARD";

//werehouse
export const SET_WAREHOUSE = "SET_WAREHOUSE";

export const SET_TOILET = "SET_TOILET";

export const SET_POOL = "SET_POOL";

export const SET_SAUNA = "SET_SAUNA";

export const SET_JACUZZI = "SET_JACUZZI";

export const SET_ELEVATOR = "SET_ELEVATOR";

export const SET_HEAT = "SET_HEAT";
//dealtype
export const SET_DEAL_TYPE="SET_DEAL_TYPE"
//usage
export const SET_USAGE="SET_USAGE"
//SET_PROPERTY_TYPE
export const SET_PROPERTY_TYPE="SET_PROPERTY_TYPE"

//sendSms
export const SET_USER_PHONE_NUMBER = "SET_USER_PHONE_NUMBER";
export const USER_IS_REGISTERD = "USER_IS_REGISTERD";
export const IS_OK_IN_MOBNUM = "IS_OK_IN_MOBNUM";
export const GET_MESSAGE = "GET_MESSAGE";
export const GET_TYPE = "GET_TYPE";

//sendCode
export const SET_USER_VERFICATION_CODE = "SET_USER_VERFICATION_CODE";
export const IS_USER_OK_IN_CONNUM = "IS_USER_OK_IN_CONNUM";
export const GET_TYPE_FROM_EXPERT = "GET_TYPE_FROM_EXPERT";
//field
export const SET_FIELD="SET_FIELD";
export const SET_CONTROL="SET_CONTROL";
export const SET_DISTRICT="SET_DISTRICT";
export const SET_SURFACE="SET_SURFACE";
//Register
export const SET_USER_NAME="SET_USER_NAME";
export const SET_AVATAR="SET_AVATAR";
export const SET_USER_EMAIL="SET_USER_EMAIL";
export const IS_USER_OK_IN_PRO="IS_USER_OK_IN_PRO";

export const IS_USER_OK_IN_PRO_INEXPERT="IS_USER_OK_IN_PRO_INEXPERT";
export const IS_USER_OK_IN_PRO_USERS="IS_USER_OK_IN_PRO_USERS";
//takhsise moshaver
export const SET_EXPERT_AVATAR="SET_EXPERT_AVATAR";
export const SET_EXPERT_NAME="SET_EXPERT_NAME";
export const IS_THREAD_OK="IS_THREAD_OK";
export const SET_PHONE_OF_EXPERT="SET_PHONE_OF_EXPERT";
export const SET_WHATSAPP_OF_EXPERT="SET_WHATSAPP_OF_EXPERT";



//profile
export const SET_AVATAR_IN_EDIT="SET_AVATAR_IN_EDIT";
export const SET_USER_EMAIL_IN_EDIT="SET_USER_EMAIL_IN_EDIT";
export const SET_IMAGE_AVATAR="SET_IMAGE_AVATAR";
export const SET_USER_NAME_IN_EDIT="SET_USER_NAME_IN_EDIT";
export const SET_USER_TITLE="SET_USER_TITLE"
export const GET_MESSAGE_IN_EXPERT= "GET_MESSAGE_IN_EXPERT"
//get user aaccount
export const SET_EXPERT_FIELD = "SET_EXPERT_FIELD";
export const SET_EXPERT_STATUS = "SET_EXPERT_STATUS";
export const TOKEN_VALIDATION_ERROR = "TOKEN_VALIDATION_ERROR";
export const SET_USER_DATA = "SET_USER_DATA";
//bookmar
export const SAVE_ID_OF_HOME="SAVE_ID_OF_HOME"
export const GET_BOOK_HOMES="GET_BOOK_HOMES"
export const OK_BOOK_HOMES="OK_BOOK_HOMES"
export const OK_DELETE_BOOK_HOMES="OK_DELETE_BOOK_HOMES"

//tof
export const GET_THREADS_COUNT = "GET_THREADS_COUNT";
export const GET_OK_TEST = "GET_OK_TEST";
//429
//addMelk
export const SET_CHANGE = 'SET_CHANGE'
export const SET_SELECT = 'SET_SELECT'
export const SET_DATE = 'SET_DATE'
export const SET_CHECK = 'SET_CHECK'
export const SET_FILE = 'SET_FILE'
export const DELETE_FILE = 'DELETE_FILE'
export const OK_SEND = 'OK_SEND'
export const OK_UPDATE = 'OK_UPDATE'
export const OK_UPLOAD = 'OK_UPLOAD'
export const SET_EDIT = 'SET_EDIT'
export const SET_EDIT_OK = 'SET_EDIT_OK'
export const SET_PROGRESS = 'SET_PROGRESS'
export const SET_NEIGHBORHOOD = 'SET_NEIGHBOR'
export const OK_ADD_NEIGHBORHOOD = 'OK_ADD_NEIGHBOR'
export const SET_NEIGHBORHOODS_LIST = 'SET_NEIGHBORHOODS_LIST'
export const SET_CHANGE_PSM = 'SET_CHANGE_PSM'
export const SET_LAT_LNG = 'SET_LAT_LNG'
export const SET_SORT = 'SET_SORT'
export const SET_SEARCH_DATA = 'SET_SEARCH_DATA'
export const SET_RESULT_DATA = 'SET_RESULT_DATA'
export const SET_NEIGHBORS = 'SET_NEIGHBORS'
export const SET_CHANGE_MELK_REQ = 'SET_CHANGE_MELK_REQ'
export const SET_LATLNG_REQ = 'SET_LATLNG_REQ'
export const SET_MELK_SEND_OK = 'SET_MELK_SEND_OK'
export const SET_EXPERT_DATA = 'SET_EXPERT_DATA'

// get post of blog
export const GET_POSTS_IN_BLOG = "GET_POSTS_IN_BLOG"
//get builders
export const GET_BUILDERS = "GET_BUILDERS"
//get expert List GET_EXPERT
export const GET_EXPERT="GET_EXPERT"

export const SET_USER_ID_EXPERT = "SET_USER_ID_EXPERT"

export const GET_HOUSE_BY_EXPERT="GET_HOUSE_BY_EXPERT"
export const GET_EXPERT_PHONE="GET_EXPERT_PHONE"
export const GET_EXPERT_HOUSE_OK="GET_EXPERT_HOUSE_OK"
export const SET_UPDATE_EXPERT="SET_UPDATE_EXPERT"
export const SET_BUILDER_ID="SET_BUILDER_ID"
export const SET_CONSTRUCT="SET_CONSTRUCT"
export const SET_UNCONSTRUCT="SET_UNCONSTRUCT"
export const SET_REMOVE_FILTERS="SET_REMOVE_FILTERS"
export const SET_DONE_VISIT="SET_DONE_VISIT"
export const SET_SPINNER="SET_SPINNER"
export const SET_WEBP_SUPPORT="SET_WEBP_SUPPORT"
export const SET_CHANGE_3="SET_CHANGE_3"

