import houses from "../../api/houses";
import * as actionTypes from "./actionTypes";

const loadExpertHouses = (neighborhoods) => ({
  type: actionTypes.LOAD_EXPERT_HOUSES,
  neighborhoods: neighborhoods,
});


export const flushHousesData = () => ({
  type: actionTypes.FLUSH_HOUSES_DATA,
});

export const setExpertHousesLength = (length) => ({
  type: actionTypes.SET_EXPERT_HOUSES_LENGTH,
  length: length,
});

const setExpertHousesLoading = (isLoading) => ({
  type: actionTypes.SET_EXPERT_HOUSES_LOADING,
  isLoading: isLoading,
});

export const setExpertHousesPage = (page) => ({
  type: actionTypes.EXPERT_HOUSES_SET_PAGE,
  page: page,
});
export const searchHouses = (page, searchChar) => async (dispatch) => {
  dispatch(setExpertHousesLoading(true));
  const response = await houses.get(`/search/neighborhood/${searchChar}/0`);
  if (response.data.ok) {
  
    dispatch(setExpertHousesLoading(false));
    dispatch(loadExpertHouses(response.data.neighborhoods));
    dispatch(setExpertHousesLength(response.data.pages));


  }
};
