import axios from "axios";
import * as actionTypes from "./actionTypes";

export const setVisitTime = (data, house) => {
    return(dispatch) =>{
        axios
            .post(`https://nikapi.nikboom.com/V1.0.0/houses/visit/time/save`,
                {
                    house,
                    date: data.toString()
                }, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('nikToken')}`,
                        'Cache-Control': 'max-age=31536000'
                    },
                }).catch((err) => {
            console.log(err)
        }).then((response) => {
            console.log(response)
            document.getElementById('Modal').style.display = 'none'
            dispatch(setDoneVisit(response.data.ok))
        })
            .catch((error) => {
                console.log(error.status);
                console.log(error);
                console.log(error.response.status);
            });
    }

}

export const setDoneVisit = (state) => ({
    type: actionTypes.SET_DONE_VISIT,
    state,
});
