import axios from "axios";
import * as actionTypes from "./actionTypes";

export const getBlogPost = () => {
  return (dispatch) => {
    axios
      .get(
        `${process.env.REACT_APP_DOMAINS_API}/${process.env.REACT_APP_DOMAINS_API_VERSION}/accounts/getblogpost`
      )
      .then((response) => {
        console.log(response);
        dispatch(getThePostOfBlog(response.data.aa));
      })
      .catch((error) => {
        console.log(error);
      });
  };
};
export const getThePostOfBlog = (getpost) => ({
    type: actionTypes.GET_POSTS_IN_BLOG,
    getpost: getpost,
});

export const getBuilders = () => {
  return (dispatch) => {
    axios
      .get(
        `${process.env.REACT_APP_DOMAINS_API}/${process.env.REACT_APP_DOMAINS_API_VERSION}/accounts/getbuilder`
      )
      .then((response) => {
        console.log(response);
        dispatch(getTheBuilders(response.data.builders));
      })
      .catch((error) => {
        console.log(error);
      });
  };
};
export const getTheBuilders = (getbuilder) => ({
    type: actionTypes.GET_BUILDERS,
    getbuilder: getbuilder,
});
export const isOkInRegister = (isOkInRegister) => ({
  type: actionTypes.IS_USER_OK_IN_PRO,
  isOkInRegister: isOkInRegister,
});
export const getTheExpertListApi = () => {
  return (dispatch) => {
    axios
      .get(
        `${process.env.REACT_APP_DOMAINS_API}/${process.env.REACT_APP_DOMAINS_API_VERSION}/accounts/experts`,
   
      )
      .then((response) => {
        console.log(response);
        dispatch(getExpert(response.data.experts));
        dispatch(isOkInRegister(response.data.ok))
      })
      .catch((error) => {
        console.log(error);
      });
  };
};
export const getExpert = (getexpert) => ({
    type: actionTypes.GET_EXPERT,
    getexpert: getexpert,
});
