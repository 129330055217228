import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import expertMelkReducer from './reducers/expertMelk'
import filterMelkReducer from './reducers/filterMelk'
import authReducer from './reducers/auth';
import userReducer from './reducers/user';
import bookReducer from './reducers/bookmark';
import profileReducer from './reducers/profile';
import addMelkReducer from "./reducers/addMelk";
import filterNewReducer from "./reducers/filterNew";
import melkRequestReducer from "./reducers/melkReq";
import blogReducer from './reducers/blog'
import profileEXpertReducer from './reducers/prrofileexpert'
import profileBuildertReducer from './reducers/profilebuilder'
import addBuilderReducer from './reducers/addbuilder'
import visitReducer from "./reducers/visit";

const rootReducer = combineReducers({
	expertMelkData: expertMelkReducer,
	filterMelkData: filterMelkReducer,
	filterNew: filterNewReducer,
	authData: authReducer,
	userData: userReducer,
	bookData: bookReducer,
	profileData: profileReducer,
	addMelk: addMelkReducer,
	melkRequest: melkRequestReducer,
	blogPost:blogReducer,
	profileexpert:profileEXpertReducer,
	profilebuilder:profileBuildertReducer,
	addbuilder:addBuilderReducer,
	visitMelk:visitReducer
});
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export default createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));