import * as actionTypes from "../actions/actionTypes";

const initialState = {
    addmelkdata: {
        deal_type: '',
        usage: '',
        image_links: [],
        alternative_image_links: [],
        address: '',
        price: 0,
        price_perSqM: 0,
        size: 0,
        loan: 0,
        rental_fee: 0,
        advisor_lname: '',
        advisor_fname: '',
        advisor_phone: '',
        advisor_whatsapp: '',
        latlng: null,
        specific: false
    },
    okSend: false,
    okUpdate: false,
    editok: false,
    fileprogress: 0,
    okUpload: false,
    neighborhood: '',
    okAddNeighborhood: false,
    neighborhoods: [],
    district: null
};

const setChange = (state, action) => ({
    ...state,
    addmelkdata: {...state.addmelkdata, [action.name]: action.data},
    district : action.name === 'address' ? action.district : null,
});

const setChange2 = (state, action) => ({
    ...state,
    addmelkdata: {...state.addmelkdata, [action.name]: action.data},
});

const setChange3 = (state, action) => ({
    ...state,
    [action.name]: action.data
});

const setProgress = (state, action) => ({
    ...state,
    fileprogress: action.data,
});

const setEdit = (state, action) => ({
    ...state,
    addmelkdata: action.data[0],
});

const setNeighborhood = (state, action) => ({
    ...state,
    neighborhood: action.value,
});

const setSort = (state, action) => ({
    ...state,
    addmelkdata: {...state.addmelkdata, image_links: action.data},
});

const setEditOk = (state, action) => ({
    ...state,
    editok: action.data,
});

const setLatLng = (state, action) => ({
    ...state,
    addmelkdata: {...state.addmelkdata, latlng: action.data},
});


const setNeighborhoodsList = (state, action) => ({
    ...state,
    neighborhoods: action.data,
});

const setCheck = (state, action) => ({
    ...state,
    addmelkdata: {...state.addmelkdata, [action.name]: action.value ? 'True' : 'False'},
});

const setFile = (state, action) => ({
    ...state,
    addmelkdata: {...state.addmelkdata, [action.name]: [...state.addmelkdata[action.name], action.value]},
});


const okSend = (state, action) => ({
    ...state,
    okSend: action.data,
});

const okUpdate = (state, action) => ({
    ...state,
    okUpdate: action.data,
});
const okAddNeighborhood = (state, action) => ({
    ...state,
    okAddNeighborhood: action.data,
});

const okUpload = (state, action) => ({
    ...state,
    okUpload: action.data,
});

const deleteFile = (state, action) => {
    let array = state.addmelkdata.image_links
    array.splice(action.index, 1)
    return (
        {
            ...state,
            addmelkdata: {...state.addmelkdata, image_links: array},
        }
    )
}


const addMelkReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_CHANGE:
        case actionTypes.SET_DATE:
        case actionTypes.SET_SELECT:
            return setChange(state, action);
        case actionTypes.SET_CHECK:
            return setCheck(state, action);
        case actionTypes.SET_FILE:
            return setFile(state, action);
        case actionTypes.DELETE_FILE:
            return deleteFile(state, action);
        case actionTypes.OK_SEND:
            return okSend(state, action);
        case actionTypes.OK_UPDATE:
            return okUpdate(state, action);
        case actionTypes.OK_ADD_NEIGHBORHOOD:
            return okAddNeighborhood(state, action);
        case actionTypes.OK_UPLOAD:
            return okUpload(state, action);
        case actionTypes.SET_EDIT:
            return setEdit(state, action);
        case actionTypes.SET_EDIT_OK:
            return setEditOk(state, action);
        case actionTypes.SET_PROGRESS:
            return setProgress(state, action);
        case actionTypes.SET_NEIGHBORHOOD:
            return setNeighborhood(state, action);
        case actionTypes.SET_NEIGHBORHOODS_LIST:
            return setNeighborhoodsList(state, action);
        case actionTypes.SET_CHANGE_PSM:
            return setChange2(state, action);
        case actionTypes.SET_CHANGE_3:
            return setChange3(state, action);
        case actionTypes.SET_LAT_LNG:
            return setLatLng(state, action);
        case actionTypes.SET_SORT:
            return setSort(state, action);
        default:
            return state;
    }
};

export default addMelkReducer;