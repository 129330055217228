import * as actionTypes from "../actions/actionTypes";

const initalState = {
  username: null,
  title: null,
  useremail: null,
  expertphone:"",
  isOkInRegister: null,
  experthouse: [],
  getokexperthouse:false,
};

const setUserName = (state, action) => ({
  ...state,
  username: action.username,
});

const setUserEmail = (state, action) => ({
  ...state,
  useremail: action.useremail,
});
const setUserTitle = (state, action) => ({
  ...state,
  title: action.title,
});
const isOkInRegister = (state, action) => ({
  ...state,
  isOkInRegister: action.isOkInRegister,
});
const setUserId = (state, action) => ({
  ...state,
  userId: action.userId,
});
const setExpertHouse = (state, action) => ({
  ...state,
  experthouse: action.experthouse,
});
 const getExpertPhone = (state, action) => ({
  ...state,
  expertphone: action.expertphone,
});
export const setOkExpertHouse = (state, action) => ({
  ...state,
  getokexperthouse: action.getokexperthouse,
  });
const reducer = (state = initalState, action) => {
  switch (action.type) {
    case actionTypes.GET_EXPERT_HOUSE_OK:
      return setOkExpertHouse(state, action);
    case actionTypes.GET_EXPERT_PHONE:
      return getExpertPhone(state, action);
    case actionTypes.SET_USER_NAME:
      return setUserTitle(state, action);
    case actionTypes.GET_HOUSE_BY_EXPERT:
      return setExpertHouse(state, action);
    case actionTypes.SET_USER_TITLE:
      return setUserName(state, action);
    case actionTypes.SET_USER_EMAIL:
      return setUserEmail(state, action);
    case actionTypes.IS_USER_OK_IN_PRO:
      return isOkInRegister(state, action);
    case actionTypes.SET_USER_ID_EXPERT:
      return setUserId(state, action);
    // case actionTypes.USER_LOGOUT:
    //   return {
    //     ...initalState,
    //   };
    default:
      return state;
  }
};

export default reducer;
