import * as actionTypes from "../actions/actionTypes";

const initialState = {
    userToken: null,
    userID: null,
    name: null,
    phone: null,
    avatar: null,
    slug: null,
    title: null,
    error: false,
    status: null,
    email: null,
    whatsappaccount: null,
    tokenValidationError: false,
    rentmelk: null,
    buymelk: null,
    successfulagreement: null,
};

const setUserData = (state, action) => {
    return {
        ...state,
        userID: action.userID,
        userToken: action.userToken,
        slug: action.slug,
        title: action.title,
        name: action.name,
        phone: action.phone,
        avatar: action.avatar,
        email: action.email,
        whatsappaccount: action.whatsappaccount,
        tokenValidationError: false,
        rentmelk: action.rentmelk,
        buymelk: action.buymelk,
        successfulagreement: action.successfulagreement,
    };
};

const tokenValidationError = (state) => ({
    ...state,
    tokenValidationError: true,
});

const setExpertField = (state, action) => ({
    ...state,
    field: action.field,
});

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_USER_DATA:
            return setUserData(state, action);
        case actionTypes.TOKEN_VALIDATION_ERROR:
            return tokenValidationError(state, action);
        case actionTypes.SET_EXPERT_FIELD:
            return setExpertField(state, action);
        case actionTypes.SET_EXPERT_STATUS:
            return {
                ...initialState
            }
        default:
            return state;
    }
};

export default reducer;
