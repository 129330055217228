import * as actionTypes from './actionTypes';
import axios from 'axios';

export const setUserName = (username) => ({
	type: actionTypes.SET_USER_NAME_IN_EDIT,
	username: username,
});
export const setAvatar = (avatarr) => ({
	type: actionTypes.SET_AVATAR_IN_EDIT,
	avatarr: avatarr,
});

export const setUserEmail = (useremail) => ({
	type: actionTypes.SET_USER_EMAIL_IN_EDIT,
	useremail: useremail,
});
export const isOkInRegister = (isOkInRegister) => ({
	type: actionTypes.IS_USER_OK_IN_PRO_USERS,
	isOkInRegister: isOkInRegister,
});
export const sendProfileData = (username, useremail,avatarr) => {
	
	return (dispatch) => {
		axios
			.post(
				`${process.env.REACT_APP_DOMAINS_API}/${process.env.REACT_APP_DOMAINS_API_VERSION}/accounts/profile`,
				{
					update: {
						name: username,
						email: useremail,
						avatar:avatarr
					},
				},
				{
					headers: {
						Authorization: 'Bearer ' + localStorage.getItem('nikToken'),
						'Cache-Control': 'max-age=31536000'
					},
				}
			)

			.then((response) => {
	
				dispatch(setUserName(username));
				dispatch(setUserEmail(useremail));
				dispatch(setAvatar(avatarr))
				dispatch(isOkInRegister(response.data.ok));
			})
			.catch((error) => {
				console.log(error);
			});
	};
};


export const setImage = (fileurl) => ({
	type: actionTypes.SET_IMAGE_AVATAR,
	fileurl:fileurl,
  });
  
  export const sendImginedit = (formData,username, useremail,avatarr) => {
	return (dispatch) => {
	  axios
			.post(`${process.env.REACT_APP_DOMAINS_CDN}/upload`, formData, {
			  headers: {
				  'Content-Type': 'multipart/form-data',
				  'Cache-Control': 'max-age=31536000'
			  },
			})
			.then((response) => {
		
	   dispatch(setImage(response.data.thumbUrl));
	   return response.data.thumbUrl
		})
		.then((thumburl) => {
		
			axios
			.post(
				`${process.env.REACT_APP_DOMAINS_API}/${process.env.REACT_APP_DOMAINS_API_VERSION}/accounts/profile`,
				{
					update: {
						name: username,
						email: useremail,
						avatar:`${thumburl}`
					},
				},
				{
					headers: {
						Authorization: 'Bearer ' + localStorage.getItem('nikToken'),
						'Cache-Control': 'max-age=31536000'
					},
				}
			)

			.then((response) => {

				dispatch(setUserName(username));
				dispatch(setUserEmail(useremail));
				dispatch(setAvatar(avatarr))
				dispatch(isOkInRegister(response.data.ok));
			})
			.catch((error) => {
				console.log(error);
			});
		  })
  
		
			.catch(err => {
			  console.log(err);
			});
	};
  };








  //tof



export const setUserData = (threadcount) => ({
  type: actionTypes.GET_THREADS_COUNT,
  threadcount: threadcount,
});
export const oktest = (oktest) => ({
	type: actionTypes.GET_OK_TEST,
	oktest: oktest,
  });
export const tokenValidationError = () => ({
	type: actionTypes.TOKEN_VALIDATION_ERROR,
  });



