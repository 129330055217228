import * as actionTypes from "../actions/actionTypes";

const initalState = {
  username: null,
  title: null,
  useremail: null,
  isOkInRegister: null,
  experthouse: [],
  expertphone: null,
  getokexperthouse: false,
  userId: null,
  update: {},
  costructedproject: null,
  underconstructionproject: null,
};
const setConstruct = (state, action) => ({
  ...state,
  costructedproject: action.costructedproject,
});
const setUnConstruct = (state, action) => ({
  ...state,
  underconstructionproject: action.underconstructionproject,
});
const setUserName = (state, action) => ({
  ...state,
  username: action.username,
});

const setUserEmail = (state, action) => ({
  ...state,
  useremail: action.useremail,
});
const setUserTitle = (state, action) => ({
  ...state,
  title: action.title,
});
const isOkInRegister = (state, action) => ({
  ...state,
  isOkInRegister: action.isOkInRegister,
});
const setUserId = (state, action) => ({
  ...state,
  userId: action.userId,
});
const setExpertHouse = (state, action) => ({
  ...state,
  experthouse: action.experthouse,
});
const getExpertPhone = (state, action) => ({
  ...state,
  expertphone: action.expertphone,
});
export const setOkExpertHouse = (state, action) => ({
  ...state,
  getokexperthouse: action.getokexperthouse,
});
const setUpdateExpert = (state, action) => ({
  ...state,
  update: { ...state.update, [action.name]: action.value },
});
const reducer = (state = initalState, action) => {
  switch (action.type) {
    case actionTypes.SET_CONSTRUCT:
      return setConstruct(state, action);

    case actionTypes.SET_UNCONSTRUCT:
      return setUnConstruct(state, action);

    case actionTypes.SET_UPDATE_EXPERT:
      return setUpdateExpert(state, action);
    case actionTypes.GET_EXPERT_HOUSE_OK:
      return setOkExpertHouse(state, action);
    case actionTypes.GET_EXPERT_PHONE:
      return getExpertPhone(state, action);
    case actionTypes.SET_USER_NAME:
      return setUserTitle(state, action);
    case actionTypes.GET_HOUSE_BY_EXPERT:
      return setExpertHouse(state, action);
    case actionTypes.SET_USER_TITLE:
      return setUserName(state, action);
    case actionTypes.SET_USER_EMAIL:
      return setUserEmail(state, action);
    case actionTypes.IS_USER_OK_IN_PRO:
      return isOkInRegister(state, action);
    case actionTypes.SET_USER_ID_EXPERT:
      return setUserId(state, action);

    default:
      return state;
  }
};

export default reducer;
