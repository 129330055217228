import axios from "axios";
import * as actionTypes from "./actionTypes";

export const searchFilter = (data, name, value, page) => {
    if (name !== '') {
        data[name] = value
    }
    return (dispatch) => {
        axios
            .post(`${process.env.REACT_APP_DOMAINS_API}/${process.env.REACT_APP_DOMAINS_API_VERSION}/search/main/${page}`,
                {query: data})
            .then((response) => {
                response.data.pages - 1 === parseInt(response.data.currentPage) ? dispatch(setCurentPage('hasMoreItems', false)) :
                    dispatch(setCurentPage('hasMoreItems', true))
                dispatch(setCurentPage('page', response.data.currentPage))
                dispatch(setCurentPage('pages', response.data.pages))
                dispatch(setResultData(response.data.houses))
                if (response.data.houses.length === 0) {
                    document.getElementById('bottomText').style.display = 'none'
                } else {
                    document.getElementById('bottomText').style.display = 'flex'
                }
                setTimeout(() => {
                    dispatch(setSpinner(false))
                }, 200)
            })
            .catch((error) => {
                console.log(error);
            });
    };
};


export const getNeighborhood = () => {
    return (dispatch) => {
        axios
            .get(`${process.env.REACT_APP_DOMAINS_API}/${process.env.REACT_APP_DOMAINS_API_VERSION}/houses/neighborhoods/load`,
                {
                    headers: {Authorization: `Bearer ${localStorage.getItem("nikToken")}`},
                }).catch((err) => {
            console.log(err)
        }).then((response) => {
            dispatch(setNeighbors(response.data.result));
        }).catch((error) => {
            console.log(error);
        });
    };
}

export const setSearchData = (name, value) => ({
    type: actionTypes.SET_SEARCH_DATA,
    name,
    value
});

export const setResultData = (data) => ({
    type: actionTypes.SET_RESULT_DATA,
    data
});

export const setRemoveFilters = () => ({
    type: actionTypes.SET_REMOVE_FILTERS,
});

export const setSpinner = (state) => ({
    type: actionTypes.SET_SPINNER,
    state
});

export const setCurentPage = (name, data) => ({
    type: actionTypes.SET_CURRENT_PAGE,
    data,
    name
});

export const setNeighbors = (neighbors) => ({
    type: actionTypes.SET_NEIGHBORS,
    neighbors
});