import axios from "axios";
import * as actionTypes from "./actionTypes";

export const sendSMS = (userMobileNumber) => {
    return (dispatch) => {
        axios
            .post(`${process.env.REACT_APP_DOMAINS_API}/${process.env.REACT_APP_DOMAINS_API_VERSION}/accounts/auth`, {
                phone: userMobileNumber,
            })

            .then((response) => {
                console.log(response.data);
                if (response.data.ok) {
                    localStorage.setItem("type", response.data.type);

                }
                // localStorage.setItem('phone', userMobileNumber);
                dispatch(setUserPhoneNumber(userMobileNumber));
                dispatch(isregister(response.data.is_registered));
                dispatch(isokinmobnum(response.data.ok));
                dispatch(getMessage(response.data.message));
                dispatch(getType(response.data.type));
            })
            .catch((error) => {
                console.log(error.status);
                console.log(error);
                console.log(error.response.status);
            });
    };
};

export const setUserPhoneNumber = (phoneNumber) => ({
    type: actionTypes.SET_USER_PHONE_NUMBER,
    phoneNumber: phoneNumber,
});

export const setWebpSupport = () => {
    let elem = document.createElement('canvas')
    if(!!(elem.getContext && elem.getContext('2d'))){
        return {
            type: actionTypes.SET_WEBP_SUPPORT,
            support: elem.toDataURL('image/webp').indexOf('data:image/webp') === 0,
        }
    }
    else{
        return {
            type: actionTypes.SET_WEBP_SUPPORT,
            support: false,
        }
    }
};

export const isregister = (registerd) => ({
    type: actionTypes.USER_IS_REGISTERD,
    registerd: registerd,
});
export const isokinmobnum = (isokinmobnum) => ({
    type: actionTypes.IS_OK_IN_MOBNUM,
    isokinmobnum: isokinmobnum,
});
export const getMessage = (getmessage) => ({
    type: actionTypes.GET_MESSAGE,
    getmessage: getmessage,
});
export const getType = (gettype) => ({
    type: actionTypes.GET_TYPE,
    gettype: gettype,
});

export const sendCodeinEnter = (phoneNumber, userCode) => {
    return (dispatch) => {
        axios
            .post(`${process.env.REACT_APP_DOMAINS_API}/${process.env.REACT_APP_DOMAINS_API_VERSION}/accounts/${localStorage.getItem('type')}/login/otp`, {
                phone: phoneNumber,
                code: userCode,
            })
            .then((response) => {
                console.log(response.data);
                console.log(phoneNumber)
                console.log(userCode)
                if (response.data.ok === true) {
                    localStorage.setItem("nikToken", response.data.token);

                }
                dispatch(setUserVerificationCode(userCode));
                dispatch(isOkInConnum(response.data.ok));
                dispatch(getMessage(response.data.message));
            })
            .catch((error) => {
                console.log(error);
            });
    };
};
export const sendCodeExpert = (phoneNumber, userCode) => {
    return (dispatch) => {
        axios
            .post(`${process.env.REACT_APP_DOMAINS_API}/${process.env.REACT_APP_DOMAINS_API_VERSION}/accounts/expert/login/otp`, {
                phone: phoneNumber,
                code: userCode,
            })
            .then((response) => {
                localStorage.setItem("nikToken", response.data.token);
                dispatch(setUserVerificationCode(userCode));
                dispatch(isOkInConnum(response.data.ok));
                dispatch(getMessage(response.data.message));
                if (response.data.type === "customer") {
                    dispatch(setTypeinexpert(response.data.type));
                }
            })
            .catch(() => {
            });
    };
};

export const setTypeinexpert = (typee) => ({
    type: actionTypes.GET_TYPE_FROM_EXPERT,
    typee: typee,
});

export const setUserVerificationCode = (code) => ({
    type: actionTypes.SET_USER_VERFICATION_CODE,
    userCode: code,
});

export const isOkInConnum = (isokinconnum) => ({
    type: actionTypes.IS_USER_OK_IN_CONNUM,
    isokinconnum: isokinconnum,
});

//field
export const setField = (values) => ({
    type: actionTypes.SET_FIELD,
    field: values,
});


export const setcontrol = (valuecontrol) => ({
    type: actionTypes.SET_CONTROL,
    control: valuecontrol,
});
export const setdistrict = (valuedistrict) => ({
    type: actionTypes.SET_DISTRICT,
    district: valuedistrict,
});
export const setSurface = (valuesurface) => ({
    type: actionTypes.SET_SURFACE,
    surface: valuesurface,
});


//register
export const sendProfileDatainhomedata = (username, useremail, avatarr, field, phoneNumber, control, district, surface) => {
    return (dispatch) => {
        axios
            .post(
                `${process.env.REACT_APP_DOMAINS_API}/${process.env.REACT_APP_DOMAINS_API_VERSION}/accounts/profile`,
                {
                    update: {
                        name: username,
                        email: useremail,
                        avatar: avatarr
                    },
                },
                {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('nikToken'),
                    },
                }
            )

            .then((response) => {
                console.log(response);
                dispatch(setUserName(username));
                dispatch(setUserEmail(useremail));
                dispatch(setAvatar(avatarr))
                dispatch(isOkInRegister(response.data.ok));


            })
            .then(() => {

                axios
                    .post(
                        `${process.env.REACT_APP_DOMAINS_API}/${process.env.REACT_APP_DOMAINS_API_VERSION}/assignment/submit`,
                        {
                            phone: phoneNumber,
                            field: field,
                            control: control,
                            district: district,
                            surface: surface,
                        },
                        {
                            headers: {
                                Authorization: "Bearer " + localStorage.getItem("nikToken"),
                            },
                        }
                    )
                    .then((response) => {
                        console.log(response);

                        console.log(response.data);
                        if (response.data.thread === false || response.data.ok === false) {
                            dispatch(getMessage(response.data.message));
                        } else {
                            dispatch(setExpertAvatar(response.data.thread.expert.avatar));
                            dispatch(setExpertName(response.data.thread.expert.name));
                            dispatch(isOkInConnum(response.data.ok));
                            dispatch(isThreadOk(response.data.thread));
                            dispatch(getMessage(response.data.message));
                            dispatch(setExpertPhone(response.data.thread.expert.phone));
                            dispatch(setExpertWhatsApp(response.data.thread.expert.whatsapp_account));

                            console.log(response.data.thread.expert.phone)
                        }
                    })

                    .catch((error) => {
                        console.log(error);
                    });
            })
            .catch((errorcode) => {
                console.log(errorcode);
            });
    };
};

//register

export const setExpertPhone = (phoneExpert) => ({
    type: actionTypes.SET_PHONE_OF_EXPERT,
    phoneExpert: phoneExpert,
});
export const setExpertWhatsApp = (whatsappExpert) => ({
    type: actionTypes.SET_WHATSAPP_OF_EXPERT,
    whatsappExpert: whatsappExpert,
});
export const setUserName = (username) => ({
    type: actionTypes.SET_USER_NAME,
    username: username,
});
export const setAvatar = (avatarr) => ({
    type: actionTypes.SET_AVATAR,
    avatarr: avatarr,
});

export const setUserEmail = (useremail) => ({
    type: actionTypes.SET_USER_EMAIL,
    useremail: useremail,
});
export const isOkInRegister = (isOkInRegister) => ({
    type: actionTypes.IS_USER_OK_IN_PRO,
    isOkInRegister: isOkInRegister,
});

//takhsise moshaver
export const setExpertAvatar = (avatar) => ({
    type: actionTypes.SET_EXPERT_AVATAR,
    avatar: avatar,
});
export const setExpertName = (name) => ({
    type: actionTypes.SET_EXPERT_NAME,
    name: name,
});
export const isThreadOk = (isthreadok) => ({
    type: actionTypes.IS_THREAD_OK,
    isthreadok: isthreadok,
});


export const sendCode = (
    phoneNumber,
    userCode,
    field,
    control,
    district,
    surface
) => {
    return (dispatch) => {
        axios
            .post(
                `${process.env.REACT_APP_DOMAINS_API}/${process.env.REACT_APP_DOMAINS_API_VERSION}/accounts/customer/login/otp`,
                {
                    phone: phoneNumber,
                    code: userCode,
                } , {
                    headers: {
                        'Cache-Control': 'max-age=31536000'
                    }
                }
            )
            .then((response) => {
                if (response.data.type === "expert") {
                    dispatch(setTypeinexpert(response.data.type));
                }
                console.log(response.data);
                localStorage.setItem("nikToken", response.data.token);
                dispatch(setUserVerificationCode(userCode));
                dispatch(isOkInConnum(response.data.ok));
                dispatch(getMessage(response.data.message));

                return response.data.token;
            })

            .then((token) => {
                axios
                    .post(
                        `${process.env.REACT_APP_DOMAINS_API}/${process.env.REACT_APP_DOMAINS_API_VERSION}/assignment/submit`,
                        {
                            phone: phoneNumber,
                            field: field,
                            control: control,
                            district: district,
                            surface: surface,
                        },
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        }
                    )
                    .then((response) => {
                        console.log(response);
                        console.log(control);
                        console.log(field);

                        if (response.data.thread === false || response.data.ok === false) {

                            dispatch(getMessage(response.data.message));
                        } else {
                            console.log(response.data.thread.expert.avatar);
                            dispatch(setExpertAvatar(response.data.thread.expert.avatar));
                            dispatch(setExpertName(response.data.thread.expert.name));
                            dispatch(setExpertPhone(response.data.thread.expert.phone));
                            dispatch(setExpertWhatsApp(response.data.thread.expert.whatsapp_account));

                            console.log(response.data.thread.expert.phone)

                            dispatch(isOkInConnum(response.data.ok));
                            dispatch(isThreadOk(response.data.thread));
                            dispatch(getMessage(response.data.message));
                            console.log(field)
                        }
                    })

                    .catch((error) => {
                        console.log(error);
                    });
            })
            .catch(() => {
            });
    };
};
export const justProfile = (username, useremail, avatarr) => {
    return (dispatch) => {
        axios
            .post(`${process.env.REACT_APP_DOMAINS_API}/${process.env.REACT_APP_DOMAINS_API_VERSION}/accounts/profile`,
                {
                    update: {
                        name: username,
                        email: useremail,
                        avatar: avatarr
                    },
                },
                {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('nikToken'),
                    },
                }
            )
            .then((response) => {
                console.log(response.data)
                dispatch(setUserName(username));
                dispatch(setUserEmail(useremail));
                dispatch(setAvatar(avatarr))
                dispatch(isOkInRegister(response.data.ok));

            })
            .catch((error) => {
                console.log(error)
                // dispatch(sendSMSError("ERROR IN otp expert"));
            });
    };
};

export const sendField = (phoneNumber, field, control, district, surface) => {
    return (dispatch) => {
        axios
            .post(
                `${process.env.REACT_APP_DOMAINS_API}/${process.env.REACT_APP_DOMAINS_API_VERSION}/assignment/submit`,
                {
                    phone: phoneNumber,
                    field: field,
                    control: control,
                    district: district,
                    surface: surface,
                },
                {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("nikToken"),
                    },
                }
            )
            .then((response) => {
                dispatch(setExpertAvatar(response.data.thread.expert.avatar));
                dispatch(setExpertName(response.data.thread.expert.name));
                dispatch(isOkInConnum(response.data.ok));
                dispatch(isThreadOk(response.data.thread));
                dispatch(setExpertPhone(response.data.thread.expert.phone));
                dispatch(setExpertWhatsApp(response.data.thread.expert.whatsapp_account));


            })

            .catch((error) => {
                console.log(error);
            });
    };
};