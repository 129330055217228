import axios from "axios";
import * as actionTypes from "./actionTypes";
import bookmark from "../../api/bookmark";

export const saveBookMark = (id) => {
    return (dispatch) => {
        axios
            .post(
                `${process.env.REACT_APP_DOMAINS_API}/${process.env.REACT_APP_DOMAINS_API_VERSION}/houses/bookmark/save`,
                {
                    house: {

                        id: id,
                    }

                },
                {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("nikToken"),
                        'Cache-Control': 'max-age=31536000'
                    },
                }
            )

            .then((response) => {
                console.log(response.data);
                dispatch(setId(id))
                dispatch(OKinSavedBokk(response.data.ok))

            })
            .catch((error) => {
                console.log(error);
                //   if(error.response.status===429){
                //     dispatch(setkhata(error.response.status));
                //   }
            });
    };
};
export const setId = (id) => ({
    type: actionTypes.SAVE_ID_OF_HOME,
    id: id,
});

export const getBookMark = () => (dispatch) => {
    const userToken = localStorage.getItem("nikToken");
    bookmark
        .get("", {
            headers: {
                Authorization: `Bearer ${userToken}`,
                'Cache-Control': 'max-age=31536000'
            },
        })
        .then((response) => {
            console.log(response.data.bookmarks[0].houses)
            dispatch(getHomeinBook(response.data.bookmarks[0].houses))
            // dispatch(id(response.data.bookmarks.houses));
            //   dispatch(oktest(response.data.ok));

        });
};
export const getHomeinBook = (bookhomes) => ({
    type: actionTypes.GET_BOOK_HOMES,
    bookhomes: bookhomes,
});
export const OKinSavedBokk = (oksavebook) => ({
    type: actionTypes.OK_BOOK_HOMES,
    oksavebook: oksavebook,
});

export const deleteBookMark = (id) => {
    return (dispatch) => {
        axios
            .post(
                `${process.env.REACT_APP_DOMAINS_API}/${process.env.REACT_APP_DOMAINS_API_VERSION}/houses/bookmark/remove`,
                {
                    house: id,

                },
                {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("nikToken"),
                        'Cache-Control': 'max-age=31536000'
                    },
                }
            )

            .then((response) => {
                console.log(response.data);
                dispatch(setId(id))
                dispatch(OKinDeleteBook(response.data.ok))
                console.log(id)
                // localStorage.setItem('phone', userMobileNumber);
            })
            .catch((error) => {
                console.log(error);
                //   if(error.response.status===429){
                //     dispatch(setkhata(error.response.status));
                //   }
            });
    };
};
export const OKinDeleteBook = (okdeletebook) => ({
    type: actionTypes.OK_DELETE_BOOK_HOMES,
    okdeletebook: okdeletebook,
});  