import * as actionTypes from "./actionTypes";
import axios from "axios";
import jwt_decode from "jwt-decode";

export const setUserName = (username) => ({
  type: actionTypes.SET_USER_NAME_IN_EDIT,
  username: username,
});
export const setAvatar = (avatarr) => ({
  type: actionTypes.SET_AVATAR_IN_EDIT,
  avatarr: avatarr,
});

export const setUserEmail = (useremail) => ({
  type: actionTypes.SET_USER_EMAIL_IN_EDIT,
  useremail: useremail,
});
export const setUserTitle = (title) => ({
  type: actionTypes.SET_USER_TITLE,
  useremail: title,
});
export const setUserId = (userId) => ({
  type: actionTypes.SET_USER_ID_EXPERT,
  userId: userId,
});
export const isOkInRegister = (isOkInRegister) => ({
  type: actionTypes.IS_USER_OK_IN_PRO,
  isOkInRegister: isOkInRegister,
});
export const sendProfileDataBuilder = (userId,username, title, costructedproject,underconstructionproject,avatarr) => {
  return (dispatch) => {
    axios
      .post(
        `${process.env.REACT_APP_DOMAINS_API}/${process.env.REACT_APP_DOMAINS_API_VERSION}/accounts/updateBuilder`,
        {
          userId:userId,
          update: {
            name: username,
            title: title,
            costructed:costructedproject,
           under_construction:underconstructionproject,
            // whatsapp_account: useremail,
            avatar: avatarr,
          },
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("nikToken"),
            'Cache-Control': 'max-age=31536000'
          },
        }
      )

      .then((response) => {
        console.log(response);
        dispatch(setUserName(username));
        dispatch(setUserId(userId));
        dispatch(setUserTitle(title));
        dispatch(setAvatar(avatarr));
        dispatch(setUnConstruct(underconstructionproject));
        dispatch(setConstruct(costructedproject));
        dispatch(isOkInRegister(response.data.ok));
      })
      .catch((error) => {
        console.log(error);
      });
  };
};
export const setConstruct = (costructedproject) => ({
  type: actionTypes.SET_CONSTRUCT,
  costructedproject: costructedproject,
});
export const setUnConstruct = (underconstructionproject) => ({
  type: actionTypes.SET_UNCONSTRUCT,
  underconstructionproject: underconstructionproject,
});
export const setImage = (fileurl) => ({
  type: actionTypes.SET_IMAGE_AVATAR,
  fileurl: fileurl,
});

export const sendImgineUpdateInBuilder = (
  formData,
  userId,
  username,
  title,
  costructedproject,underconstructionproject,
  avatarr,
) => {
  return (dispatch) => {
    axios
      .post(`${process.env.REACT_APP_DOMAINS_CDN}/upload`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          'Cache-Control': 'max-age=31536000'
        },
      })
      .then((response) => {
        dispatch(setImage(response.data.thumbUrl));
        return response.data.thumbUrl;
      })
      .then((thumburl) => {
        axios
          .post(
            `${process.env.REACT_APP_DOMAINS_API}/${process.env.REACT_APP_DOMAINS_API_VERSION}/accounts/updateBuilder`,
            {
                userId:userId,
                update: {
                    name: username,
                  title: title,
                  costructed:costructedproject,
                  under_construction:underconstructionproject,
                  // whatsapp_account: useremail,
                  avatar: `${thumburl}`,
                  
                },
              },
       
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("nikToken"),
                'Cache-Control': 'max-age=31536000'
              },
            }
          )

          .then((response) => {
            console.log(response.data);
            dispatch(setUserName(username));
            dispatch(setUserId(userId));
            dispatch(setUserTitle(title));
            dispatch(setAvatar(avatarr));
            dispatch(setUnConstruct(underconstructionproject));
            dispatch(setConstruct(costructedproject));
            dispatch(isOkInRegister(response.data.ok));
          })
          .catch((error) => {
            console.log(error);
          });
      })

      .catch((err) => {
        console.log(err);
      });
  };
};

//tof

export const setUserData = (threadcount) => ({
  type: actionTypes.GET_THREADS_COUNT,
  threadcount: threadcount,
});
export const oktest = (oktest) => ({
  type: actionTypes.GET_OK_TEST,
  oktest: oktest,
});
export const tokenValidationError = () => ({
  type: actionTypes.TOKEN_VALIDATION_ERROR,
});

export const getHouseByExpert = () => {
  const token = localStorage.getItem("nikToken");
  let decoded = jwt_decode(token);
  return (dispatch) => {
    axios
      .post(
        `${process.env.REACT_APP_DOMAINS_API}/${process.env.REACT_APP_DOMAINS_API_VERSION}/houses/gethousebyexpert`,
        {
          expert_phone: decoded.phone,
        },
        
      )

      .then((response) => {
        console.log(response);
      
        dispatch(setExpertHouse(response.data.houses));
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const setExpertHouse = (experthouse) => ({
  type: actionTypes.GET_HOUSE_BY_EXPERT,
  experthouse: experthouse,
});
export const getExpertPhone = (expertphone) => ({
	type: actionTypes.GET_EXPERT_PHONE,
	expertphone: expertphone,
  });
  export const setOkExpertHouse = (getokexperthouse) => ({
    type: actionTypes.GET_EXPERT_HOUSE_OK,
    getokexperthouse: getokexperthouse,
    });
export const getHouseExpertByPhone = (expertphone) => {
	
	return (dispatch) => {
	  axios
		.post(
		  `${process.env.REACT_APP_DOMAINS_API}/${process.env.REACT_APP_DOMAINS_API_VERSION}/houses/gethousebyexpert`,
		  {
			expert_phone: expertphone,
		  },
		  
		)
  
		.then((response) => {
		  console.log(response);
      dispatch(setOkExpertHouse(response.data.ok));
      if(response.data.ok){
        dispatch(setExpertHouse(response.data.houses));

      }		})
		.catch((error) => {
		  console.log(error);
		});
	};
  };

  export const getAuthByIdBuilder = (userId) => {
    // const token = localStorage.getItem("nikToken");
    // let decoded = jwt_decode(token);
    return (dispatch) => {
      axios
        .get(
          `${process.env.REACT_APP_DOMAINS_API}/${process.env.REACT_APP_DOMAINS_API_VERSION}/accounts/getbuilderbybuilderid`,
          {
            builder_id:userId
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("nikToken"),
              'Cache-Control': 'max-age=31536000'
            },
          }
        )
  
        .then((response) => {
          console.log(response);
         dispatch(setUserId(userId));
  
        })
        .catch((error) => {
          console.log(error);
        });
    };
  };