import * as actionTypes from "../actions/actionTypes";

const initialState = {
    melkreqdata: {
        deal_type: '',
        usage: '',
        price: 0,
        size: 0,
        property_type:'',
        neighbor:'',
        latlng:null,
        rental_fee: 0,
        loan: 0
    },
    melksendok: false,
    expert:{}
};

const setChangeMelkReq = (state, action) => ({
    ...state,
    melkreqdata: {...state.melkreqdata, [action.name]: action.data},
});

const setMelkSendOk = (state, action) => ({
    ...state,
    melksendok: action.data,
});

const setExpertData = (state, action) => ({
    ...state,
    expert: action.data,
});

const setLatLngReq = (state, action) => ({
    ...state,
    melkreqdata: {...state.melkreqdata, latlng: action.data},
});

const melkRequestReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_CHANGE_MELK_REQ:
            return setChangeMelkReq(state, action);
        case actionTypes.SET_MELK_SEND_OK:
            return setMelkSendOk(state, action);
        case actionTypes.SET_EXPERT_DATA:
            return setExpertData(state, action);
        case actionTypes.SET_LATLNG_REQ:
            return setLatLngReq(state, action);
        default:
            return state;
    }
};

export default melkRequestReducer;